import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"

import LOGISTIC_ICON from "../../images/content/services/shutterstock_509571454.jpg"
import PREMOVE_PLANNING_ICON from "../../images/content/services/shutterstock_425734180.jpg"
import RIGGING_ICON from "../../images/content/services/shutterstock_214476049.jpg"
import PACKAGING_ICON from "../../images/content/services/shutterstock_230908780.jpg"
import STORAGE_ICON from "../../images/content/services/logistic-services-storage.jpg"
import TRANSPORT_ICON from "../../images/content/services/shutterstock_312715829.jpg"

const LogisticServices = () => {
  return (
    <>
      <Layout>
        <SEO
          title={`Lab Equipment Logistic Services: Crating, Storage and Freight`}
          description={`With each equipment purchase, there is a lot to coordinate. Our team at Surplus Solutions has developed a network of companies that we trust to handle your equipment safely and professionally. No matter what the size, we provide a customized option for crating, skidding and freight services.`}
          />

        <div className="row column">
          <div className="row">
            <div className="medium-6 columns ">
              <div>
                <a href="/our-services/logistic-services">
                  <img src={LOGISTIC_ICON} alt="Logistic Services" />
                </a>
              </div>
            </div>
            <div className="medium-6 columns">
              <h1>Logistic Services</h1>
              <hr />
              <p>With each equipment purchase, there is a lot to coordinate. Our team at SSLLC has developed a network of companies that we trust to handle your equipment safely and professionally. No matter what the size, we provided a customized option for crating, skidding and freight services.</p>
            </div>
          </div>
          <hr className="bg-blue mt6 mb8" />
          <div className="row">
            <div className="medium-6 columns mb6">
              <div>
                <img className="border" src={PREMOVE_PLANNING_ICON} alt="Pre-move Planning and Consulting" title="Pre-move Planning and Consulting" width="411" />
              </div>
            </div>
            <div className="medium-6 columns mb6">
              <h3 className="headings xshort">Pre-move Planning and Consulting</h3>
              <p>Our experts evaluate your specific requirements and implement a plan that ensures your move will be done safely, according to schedule, and within
                your budget.</p>
            </div>
          </div>
          <div className="row">
            <div className="medium-6 columns mb6">
              <div>
                <img className="border" src={RIGGING_ICON} alt="Rigging" title="Rigging" width="404" />
              </div>
            </div>
            <div className="medium-6 columns mb6">
              <h3 className="headings xshort caps">Rigging</h3>
              <p>Surplus Solutions works exclusively with certified riggers, so you can rest assured that all safety and inspection procedures are met.</p>
            </div>
          </div>
          <div className="row">
            <div className="medium-6 columns mb6">
              <div>
                <img className="border" src={PACKAGING_ICON} alt="Packaging and Crating" title="Packaging and Crating" width="404" />
              </div>
            </div>
            <div className="medium-6 columns mb6">
              <h3 className="headings xshort caps">Packaging and Crating</h3>
              <p>Our experts use only the best packaging materials specific to the kind of equipment you’re moving.</p>
            </div>
          </div>
          <div className="row">
            <div className="medium-6 columns mb6">
              <div>
                <img className="border" src={STORAGE_ICON} alt="Storage" title="Storage" />
              </div>
            </div>
            <div className="medium-6 columns mb6">
              <h3 className="headings xshort caps">Storage</h3>
              <p>Rather than use expensive storage vendors, Surplus Solutions offers an affordable alternative with our own storage facility in Fall River, MA,
                and with local partners in other areas.</p>
            </div>
          </div>
          <div className="row">
            <div className="medium-6 columns mb6">
              <div>
                <img className="border" src={TRANSPORT_ICON} alt="Transport" title="Transport" width="404" />
              </div>
            </div>
            <div className="medium-6 columns mb6">
              <h3 className="headings xshort caps">Transport</h3>
              <p>Whether your move is local or national, we have reliable networks that ensure your move is seamless. We will evaluate your requirements to create
                a specific plan. Whether your equipment is best moved by truck, air, boat or rail, we have the resources to ensure proper delivery.</p>
            </div>
          </div>
          <hr className="bg-blue mb8" />
          <div className="row column">
            <h5 className="caps mb0">Rest Assured</h5>
            <p>Why risk selecting the wrong company for your move? Choosing a company to transport and install your assets is overwhelming. We know which companies
            are best suited to move all types of equipment, which companies have the best resources for cross country transport, which local companies have
            the best record. Through our years of experience, we have developed a network of professionals who can handle your equipment properly, safely,
            and promptly.</p>
            <h5 className="caps mb0">Comprehensive Service</h5>
            <p>We understand what it takes to complete a relocation, and make sure that every last detail is taken care of. We provide an experienced team that manages
            your project each step of the way. Leave the details to the experts!</p>
            <h5 className="caps mb0">Save Money</h5>
            <p>When you trust Surplus Solutions to manage your logistics and relocation, you eliminate the need to manage multiple vendors and subcontractors to complete
            your transition. We negotiate on your behalf and ensure that your relocation is done properly and within your budget.</p>
            <h5 className="caps mb0">Safety First</h5>
            <p>In each move, we have a dedicated fleet of AirRide Trucks to ensure safe delivery of your assets. You can’t trust your instruments and equipment to
            just any fleet. With many common carriers, your equipment may be loaded and unloaded at each stop along the route to its new home, subjecting it
            to unnecessary risk. AirRide Trucks utilize the best suspension technology available, and provide our clients with complete peace of mind.</p>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default LogisticServices
